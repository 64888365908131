// Here you can add other styles
.c-icon-custom-size {
  display: none !important;
}
.c-sidebar-dark {
  background-color: rgb(23, 32, 48) !important;
}
.c-sidebar-nav {
  background-color: rgb(23, 32, 48) !important;
  font-family: Calibri, Arial, Helvetica, sans-serif !important;
}
.c-sidebar-nav-item:hover,
.c-sidebar-nav-link:hover {
  background-color: darkred !important;
  font-family: Calibri, Arial, Helvetica, sans-serif !important;
}

select.form-control:focus,
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
  border: 1px solid gray;
}
select.form-control,
textarea,
textarea.form-control,
input.form-control,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
[type="text"].form-control,
[type="password"].form-control,
[type="email"].form-control,
[type="tel"].form-control,
[contenteditable].form-control {
  background-color: (232, 240, 254) !important;
  border: 1px solid lightgray;
}

.card-body {
  min-height: 400px !important;
}
#login-card-body {
  min-height: 100px !important;
}

.swal2-container.swal2-center > .swal2-popup {
  //border-radius: 0 !important;
}
.swal2-styled.swal2-confirm,
.swal2-styled.swal2-deny {
  //border-radius: 0 !important;
  background-color: darkred !important;
}
.swal2-default-outline,
.swal2-styled {
  //border-radius: 0 !important;
  min-width: 25% !important;
}
.swal2-styled.swal2-confirm:focus,
.swal2-styled.swal2-confirm:active,
.swal2-cancel:focus,
.swal2-cancel:active {
  outline: none !important;
  box-shadow: none !important;
}
.swal2-actions button {
  background-color: darkred;
}
.page-item.disabled,
.page-link {
  color: gray !important;
}
.page-item.active .page-link {
  background-color: darkred !important;
  border: 1px solid darkred !important;
  color: white !important;
  box-shadow: inset 0 -1px 0 #ddd;
}
.page-item.first .page-link {
  background-color: yellow !important;
}
.page-item.last .page-link {
  background-color: blue !important;
}
.btn-brand {
  background-color: red;
  color: white;
  border: 1px solid red;
  box-shadow: inset 0 0 0 #ddd;
}
.btn-brand:hover,
.btn-brand:active,
.btn-brand:focus {
  background-color: darkred;
  color: white;
  border: 1px solid darkred;
  box-shadow: inset 0 0 0 #ddd;
}
.btn:active,
.btn:hover,
.btn:focus,
.c-switch-slider {
  box-shadow: inset 0 0 0 #ddd !important;
}
.input-brand {
  height: 40px;
  border: 1px solid lightgray;
}
.input-brand:focus {
  height: 40px;
  border: 1px solid red !important;
}
.breadcrumb-item a {
  color: gray !important;
  text-decoration: underline !important;
}
.c-sidebar-minimizer {
  display: none !important;
}
.filepond--credits {
  display: none !important;
}
.widget-success {
  background-color: darkgreen;
  color: white;
  border-radius: 5px;
  padding: 20px;
}
.widget-danger {
  background-color: darkred;
  color: white;
  border-radius: 5px;
  padding: 20px;
}
.widget-primary {
  background-color: darkblue;
  color: white;
  border-radius: 5px;
  padding: 20px;
}
.widget-secondary {
  background-color: gray;
  color: white;
  border-radius: 5px;
  padding: 20px;
}
@media (max-width: 996px) {
  .container-fluid {
    position: relative;
    top: 0px;
    margin-bottom: 90px;
  }
}
